.cp-meet-status-scheduled-shadow {
    box-shadow: 0px 2px 8px 0px rgba(255, 255, 255, 0.25) inset, 0px -4px 4px -2px rgba(0, 0, 0, 0.15) inset;
}

.cp-meet-status-completed-shadow {
    box-shadow: 0px 2px 8px 0px rgba(255, 255, 255, 0.25) inset, 0px -4px 4px -2px rgba(0, 0, 0, 0.15) inset;
}

.capture-image .modal-content,
.capture-image .modal-content video {
    border-radius: 12px !important;
}